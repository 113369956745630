import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as _Link } from "gatsby";
import { Header3 } from "../Text";

export default function NavBar() {
  return (
    <>
      <MobileView>
        <MobileNavBar />
      </MobileView>
      <DesktopView>
        <DesktopNavBar />
      </DesktopView>
    </>
  );
}

const MobileView = styled.div`
  @media (min-width: 750px) {
    display: none;
  }
`;

const DesktopView = styled.div`
  @media (max-width: 749px) {
    display: none;
  }
`;

const MobileNavBar = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <>
      <Nav>
        <Link to="/">
          <Button
            style={{
              fontFamily: "Sacramento",
              fontSize: 24,
              fontWeight: 200,
            }}
          >
            YourStory
          </Button>
        </Link>
        <div
          style={{ padding: 8 }}
          onClick={() => setShowMenu((state) => !state)}
        >
          <Action>Menu</Action>
        </div>
      </Nav>
      <MobileNav
        style={
          showMenu
            ? { width: "100vw", height: 500 }
            : { width: 0, height: 0, borderWidth: 0 }
        }
      >
        <NavBarLink to="/About">About</NavBarLink>
        <NavBarLink to="/Services">Services</NavBarLink>
        <NavBarLink to="/Thoughts">Thoughts</NavBarLink>
        <NavBarLink to="/Stories">Stories</NavBarLink>
        <NavBarAction to="/Contact">Contact</NavBarAction>
      </MobileNav>
    </>
  );
};

const DesktopNavBar = () => {
  return (
    <Nav>
      <Link to="/">
        <Button
          style={{ fontFamily: "Sacramento", fontSize: 32, fontWeight: 200 }}
        >
          YourStory
        </Button>
      </Link>
      <NavBarLink to="/About">About</NavBarLink>
      <NavBarLink to="/Services">Services</NavBarLink>
      <NavBarLink to="/Thoughts">Thoughts</NavBarLink>
      <NavBarLink to="/Stories">Stories</NavBarLink>
      <NavBarAction to="/Contact">Contact</NavBarAction>
    </Nav>
  );
};

export const NavBarLink = ({
  to,
  children,
}: {
  to: string;
  children: string;
}) => {
  const [active, setActive] = useState(false);

  return (
    <Link
      to={to}
      getProps={({ isCurrent }) => {
        setActive(isCurrent);
      }}
    >
      <Button
        style={
          active
            ? {
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                borderBottomColor: "var(--accent)",
              }
            : {}
        }
      >
        {children}
      </Button>
    </Link>
  );
};

export const NavBarAction = ({
  to,
  children,
}: {
  to: string;
  children: string;
}) => {
  return (
    <Link to={to}>
      <Action>{children}</Action>
    </Link>
  );
};

const Nav = styled.nav`
  border-bottom-width: 1px;
  border-bottom-color: #cecccc;
  border-bottom-style: solid;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  background-color: #fbfbfb;
  overflow: hidden;
  transition: height 0.2s linear;
`;

const MobileNav = styled.nav`
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  display: flex;
  background-color: #fbfbfb;
  overflow: hidden;
  transition: height 0.2s ease-in-out;
  position: relative;
`;

const Link = styled(_Link)`
  text-decoration: none;
  padding: 12px;
`;

const Button = styled(Header3)`
  padding: 8px;

  &:hover {
    color: var(--accent);
  }
`;

const Action = styled(Header3)`
  border: 2px solid var(--accent);
  padding: 8px;
  border-radius: 42px;

  &:hover {
    background-color: var(--accent);
    color: #fbfbfb;
  }
`;
