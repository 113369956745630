import React, { useState } from "react";
import styled from "styled-components";
import { Header1, Header2, Header3 } from "../Text";

const Page = styled.div`
  min-height: calc(100vh - 96px - 20vh + 1px);
  padding-top: 10vh;
  padding-bottom: 10vh;
`;

const Horizontal = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

const Info = styled.div`
  min-height: 100px;
  height: fit-content;
  /* max-height: 400px; */

  display: inline-flex;
  flex-direction: column;

  margin-bottom: 1vh;
`;

const StyledTitle = styled(Header1)`
  font-family: Sacramento;
  font-size: calc(48px + 4vw);
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default function Contact() {
  return (
    <>
      <Page>
        <Info>
          <div>
            <Header3>Contact</Header3>
            <StyledTitle>Your Story</StyledTitle>
          </div>
          <Header2>Sally Grainger</Header2>
          <Horizontal>
            <Header3>Mobile:</Header3>
            <a href="tel:+61413277036">
              <StyledLinkText>0413 277 036</StyledLinkText>
            </a>
          </Horizontal>
          <Horizontal>
            <Header3>Email:</Header3>
            <a href="mailto:sallygrainger3@gmail.com">
              <StyledLinkText>sallygrainger3@gmail.com</StyledLinkText>
            </a>
          </Horizontal>
        </Info>
        <Form url="https://formspree.io/f/xjvpkezz" />
      </Page>
    </>
  );
}

const StyledLinkText = styled(Header3)`
  margin: 0;
  padding: 0;
`;

export function Form({
  url = "http://localhost:8000/Contact",
}: {
  url?: string;
}) {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setSending(true);
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ name, email, message }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(({ ok }) => {
        setSending(false);
        setSuccess(ok);
        setError(!ok);
      })
      .catch((error) => {
        setSending(false);
        setSuccess(false);
        setError(true);
        console.warn(error);
      });
  }

  if (sending) {
    return <Header3>Sending...</Header3>;
  }

  if (success) {
    return (
      <>
        <Header3 style={{ color: "var(--success)" }}>Sent!</Header3>
        <Header2>Speak to you soon</Header2>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header3 style={{ color: "var(--error)" }}>
          Oh no! An error occurred.
        </Header3>
        <Header2>Check your Wifi</Header2>
        <Header2>Reload the page and try again</Header2>
      </>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="name">
        <StyledLabelHeader>Name</StyledLabelHeader>
      </label>
      <StyledInput
        id="name"
        placeholder="Jane Appleseed"
        value={name}
        onChange={(event) => setName(event.target.value)}
      />
      <label htmlFor="email">
        <StyledLabelHeader>Email</StyledLabelHeader>
      </label>
      <StyledInput
        id="email"
        placeholder="jane.appleseed@example.com"
        required
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <label htmlFor="message">
        <StyledLabelHeader>Message</StyledLabelHeader>
      </label>
      <StyledTextArea
        id="message"
        placeholder="Tell me about your family history"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
      />
      <button type="submit" style={{ backgroundColor: "var(--accent)" }}>
        <StyledSendText>Send</StyledSendText>
      </button>
    </form>
  );
}

const StyledSendText = styled(Header3)`
  color: var(--background);
  font-size: calc(16px + 1em);
`;

const StyledLabelHeader = styled(Header3)`
  padding-top: 16px;
`;

const StyledInput = styled.input`
  font-size: 16px;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  height: 128px;
  margin-bottom: 8px;
`;

export function Footer() {
  return (
    <>
      <hr style={{ marginTop: 50 }} />
      <footer style={{ padding: 20, margin: 20 }}>
        <Contact />
      </footer>
    </>
  );
}
