import styled from "styled-components";

interface TextProps {
  white?: boolean;
}

export const Header1 = styled.h1`
  color: ${({ white }: TextProps) => (white ? "#ebeded" : "#121313")};

  font-weight: 800;
  margin-top: 100px;
  margin-bottom: 100px;

  font-size: calc(32px + 2vw);
`;

export const Header2 = styled.h2`
  color: ${({ white }: TextProps) => (white ? "#e6ebeb" : "#181a1a")};

  font-weight: 700;
`;

export const Header3 = styled.h3`
  color: ${({ white }: TextProps) => (white ? "#e1e8e8" : "#1b1d1d")};

  font-weight: 600;
`;

export const Header4 = styled.h4`
  color: ${({ white }: TextProps) => (white ? "#dce6e6" : "#252727")};

  font-weight: 500;
`;

export const Header5 = styled.h5`
  color: ${({ white }: TextProps) => (white ? "#d8e3e3" : "#2f3131")};

  font-weight: 400;
`;

export const Header6 = styled.h6`
  color: ${({ white }: TextProps) => (white ? "#d5e0e0" : "#2f3131")};

  font-weight: 300;
`;

export const Paragraph = styled.p`
  color: ${({ white }: TextProps) => (white ? "#cedbdb" : "#2a2b2b")};

  margin: 4px;
  padding: 4px;
`;

export const Title = styled(Header1)`
  font-family: Montserrat;
  font-weight: 200;
  padding: 10px;
  font-size: calc(12px + 4vw);
  margin-bottom: calc(8px + 6vh);
  margin-top: calc(8px + 6vh);
`;

export const FancySubHeader = styled(Header2)`
  padding-bottom: 1vw;
  font-family: Cormorant Infant;
  font-size: 32px;
  font-weight: 500;
`;
