import React from "react";
import styled from "styled-components";
import NavBar from "../navBar";

import "./index.css";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const Layout = ({ children }: Props) => {
  return (
    <>
      <NavBar />
      <Flex>{children}</Flex>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  align-items: center;
  min-height: 60vh;
  flex-direction: column;
`;
